// import { Partner } from "@/service/UserService";

export const VcatColorPalette: { [key: string]: string; } = {
    primary: '#005EEA',
    primaryBg: '#E5EFFD',
    primaryLight: '#AAC9F8',
    primaryDark: '#004EC3',
    secondary: '#0BC86D',
    secondaryBg: '#E7FAF0',
    secondaryLight: '#AEEDCE',
    secondaryDark: '#09A75B',
    success: '#06C755',
    successBg: '#E7FAF0',
    successLight: '#ACECC6',
    successDark: '#05A647',
    warning: '#FCB400',
    warningBg: '#FFF8E5',
    warningLight: '#FEE6AA',
    warningDark: '#D29600',
    error: '#FF334B',
    errorBg: '#FFEBED',
    errorLight: '#FFBBC3',
    errorDark: '#D52A3F',
    errorDisabled: '#FFD6DB',
    text: '#212529',
    textSecondary: '#495057',
    textTertiary: '#868E96',
    textPlaceholder: '#ADB5BD',
    textDisabled: '#CED4DA',
    buttonSecondary: '#E5EFFD',
    buttonTertiary: '#F1F3F5',
    white: '#FFFFFF',
    p25: '#E5EFFD',
    p50: '#CCDFFB',
    p100: '#AAC9F8',
    p200: '#80AEF5',
    p300: '#5594F1',
    p400: '#2A79ED',
    p500: '#005EEA',
    p600: '#004EC3',
    p700: '#003F9C',
    p800: '#002F75',
    p900: '#001F4E',
    g50: '#F8F9FA',
    g100: '#F1F3F5',
    g200: '#E9ECEF',
    g300: '#DEE2E6',
    g400: '#CED4DA',
    g500: '#ADB5BD',
    g600: '#868E96',
    g700: '#495057',
    g800: '#343A40',
    g900: '#212529',
    r25: '#FFEBED',
    r50: '#FFD6DB',
    r100: '#FFBBC3',
    r200: '#FF99A5',
    r300: '#FF7787',
    r400: '#FF5569',
    r500: '#FF334B',
    r600: '#D52A3F',
    r700: '#AA2232',
    r800: '#801926',
    r900: '#551119',
};

export const VCAT_COLOR = {
    'primary': 'primary',
    'success': 'success',
    'warning': 'warning',
    'error': 'error',
    'errorDisabled': 'errorDisabled',
    'text': 'text',
    'textSecondary': 'textSecondary',
    'textTertiary': 'textTertiary',
    'textPlaceholder': 'textPlaceholder',
    'textDisabled': 'textDisabled',
    'white': 'white'
} as const;


const VcatHyundaiColors: { [key: string]: string; } = {
    primary: '#002c5f',
    primaryBg: '#e5e9ef',
    primaryLight: '#b2bfcf',
    primaryDark: '#00234c',
    buttonSecondary: '#e4dcd3',
    buttonTertiary: '#f6f3f2',
    // error: '#e63312',
    // errorBg: '#fceae7',
    // errorLight: '#f7c1b7',
    // errorDark: '#b8280e',
    // errorDisabled: '#fad6cf',
    text: '#000000',
    textSecondary: '#494848',
    textTertiary: '#7B7979',
    textPlaceholder: '#939191',
    textDisabled: '#ACAAA9',
    p25: '#e5e9ef',
    p50: '#ccd4df',
    p100: '#b2bfcf',
    p200: '#99aabf',
    p300: '#66809f',
    p400: '#32567e',
    p500: '#002c5f',
    p600: '#00234c',
    p700: '#001a39',
    p800: '#001126',
    p900: '#000813',
    g50: '#FAF9F8',
    g100: '#F6F3F2',
    g200: '#DDDAD9',
    g300: '#C4C2C1',
    g400: '#ACAAA9',
    g500: '#939191',
    g600: '#7B7979',
    g700: '#494848',
    g900: '#000000',
    // r25: '#fceae7',
    // r50: '#fad6cf',
    // r100: '#f7c1b7',
    // r200: '#f5ada0',
    // r300: '#f08470',
    // r400: '#eb5b41',
    // r500: '#e63312',
    // r600: '#b8280e',
    // r700: '#8a1e0a',
    // r800: '#5c1407',
    // r900: '#2e0a03'
};

const mergeCustomColors = (partnerOrganization?: string) => {
    let customColorMap: { [key: string]: string; } = {};
    switch (partnerOrganization) {
        case "hyundai-bms":
            customColorMap = VcatHyundaiColors;
            break;
    }
    for (let k of Object.keys(customColorMap)) {
        VcatColorPalette[k] = customColorMap[k];
    }
    return;
};

// declare var partner: Partner;
// mergeCustomColors(partner?.organization);

export type VColor = typeof VCAT_COLOR[keyof typeof VCAT_COLOR];