import UserService, { AuthUser } from "@/service/UserService";
import { StoreApi } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";

export interface VcatEventSourceData<T> {
    topic: string,
    data: T;
}


const DEFAULT_EVENT_SOURCE_HOST = `${import.meta.env.VITE_VCAT_SSE_EVENT_URL ? import.meta.env.VITE_VCAT_SSE_EVENT_URL : ''}`;
const DEFAULT_EVENT_SOURCE_PATH = `${import.meta.env.VITE_VCAT_SSE_EVENT_PATH ? import.meta.env.VITE_VCAT_SSE_EVENT_PATH : '/service/event/sse/event'}`;


const EVENT_SOURCE = `${DEFAULT_EVENT_SOURCE_HOST}${DEFAULT_EVENT_SOURCE_PATH}`;
const EVENT_SOURCE_OPTION = DEFAULT_EVENT_SOURCE_HOST.length > 0 ? { withCredentials: true } : undefined;
if (DEFAULT_EVENT_SOURCE_HOST.length > 0) {

}

export class VcatEventSourceHandler {
    private es: EventSource = undefined!;
    private handlerMap: Map<String, Array<(data: VcatEventSourceData<any>) => void>> = new Map();
    private retryCount = 0;
    private timeoutCloser: number | undefined = undefined!;

    constructor() {
    }

    public registerHandler = (topic: string, handler: (data: VcatEventSourceData<any>) => void) => {
        if (!this.handlerMap.has(topic)) {
            this.handlerMap.set(topic, []);
        }

        const arrays = this.handlerMap.get(topic)!;
        arrays.push(handler);
        return () => {
            const idx = arrays.findIndex(e => e === handler)!;
            console.log("remove event listener index", idx, arrays.length);
            if (idx >= 0) {
                arrays.splice(idx!, 1);
                console.log("removed event listener index", idx, arrays.length);
            }
            // const storedHandler = this.handlerMap.get(topic);
            // if (storedHandler === handler) {
            //     // console.log("remove handler ", topic);
            //     this.handlerMap.delete(topic);
            // }
        };
    };

    public connect = (userId: number) => {
        this.reconnect(userId);
    };
    private reconnect = (userId: number) => {
        if (this.es) {
            // console.log(this.es.readyState, this.es.CONNECTING, this.es.OPEN, this.es.CLOSED);
            if (this.es.readyState !== this.es.CLOSED) {
                this.es.close();
            }

        }
        this.es = new EventSource(EVENT_SOURCE, EVENT_SOURCE_OPTION);

        // const timeoutId = setTimeout(() => {
        //     // console.log("close sse", this.es.readyState, this.es.OPEN, this.es.CONNECTING, this.es.CLOSED);
        //     this.es.close();
        //     this.reconnect(userId);
        // }, 5000);

        this.es.onerror = async (evt) => {
            // console.log("on error", evt);
            this.retryCount++;
            //TODO setTimeout으로 Wait 을 줘야하려나. 그 사이에 발생한 이벤트는?
            if (this.es.readyState === this.es.CONNECTING) {
                this.es.close();
            }
            await new Promise((res) => {
                this.timeoutCloser = setTimeout(() => {
                    // clearTimeout(timeoutId);
                    res(true);
                }, 5000) as any;
            });
            this.reconnect(userId);

        };

        this.es.onmessage = (evt) => {
            // console.log("handle vcat event", evt);
            try {
                const evtData = JSON.parse(evt.data) as VcatEventSourceData<any>;
                if (this.handlerMap.has(evtData.topic)) {
                    this.handlerMap.get(evtData.topic)?.forEach(handler => handler(evtData));
                } else {
                    console.log('drop event', evtData, this.handlerMap);
                }
            } catch (e) {
                console.error('server event handle error', e);
                //ignore
            }

        };

        this.es.onopen = () => {
            // console.log("connected");
            this.timeoutCloser = undefined!;
        };

    };



    destory = () => {
        if (this.timeoutCloser) {
            clearTimeout(this.timeoutCloser);
        }
        if (this.es) {
            this.es.close();
        }
    };

}

interface IGlobalStore {
    user: AuthUser,
    initialized: boolean,
    eventHandler: VcatEventSourceHandler,
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => Promise<void>;
    hasRole: (roles: Array<string>) => boolean;
}



declare var vcatUser: AuthUser;
let swap = vcatUser;
vcatUser = undefined!;
const GlobalStoreBuilder = (set: StoreApi<IGlobalStore>['setState'], get: StoreApi<IGlobalStore>['getState']) => {
    let pionUser: AuthUser = swap!;
    let initialized = false;
    let eventHandler = new VcatEventSourceHandler();

    const signIn = async (email: string, password: string) => {
        const resp = await UserService.signIn(email, password);
        if (resp.data) {
            const whoami = await UserService.whoami();
            set({ user: whoami.data.me });
        }
    };

    const signOut = async () => {
        if (get().user) {
            await UserService.signOut();
            set({ user: undefined! });
            window.location.reload();
        }
    };

    const hasRole = (roles: Array<string>) => {
        const user = get().user;
        return user && roles.some(role => user.authorities.includes(role));
    };


    const refresh = async () => {
        // const activeOrder = await ;
        // const result = await UserService.whoami();
        const [result] = await Promise.all([UserService.whoami()]);
        if (!result.error) {
            let user = get().user;
            let latestUser = result.data.me;
            if (!user || !(['name', 'contract', 'company'] as Array<keyof AuthUser>).every(k => user[k] === latestUser[k])) {
                set({ user: result.data.me });
            }
            connectEventHandler(latestUser);
        }
    };

    const connectEventHandler = async (user: AuthUser) => {
        await eventHandler.connect(user.id);
    };

    initialized = true;

    if (pionUser) {
        refresh();
    }
    return {
        user: pionUser,
        initialized,
        eventHandler,
        signIn,
        signOut,
        hasRole,
    };
};

export default class GlobalStore {

    public static use = createWithEqualityFn<IGlobalStore>((set, get) => ({ ...GlobalStoreBuilder(set, get) }));
}

