import { VColor, VcatColorPalette } from '@/common/styles/VColor';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/system';
import React, { ElementType, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

type GranularSpeed = {
    type: 'keyStrokeDelayInMs';
    value: number;
};
type Speed = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 | 60 | 61 | 62 | 63 | 64 | 65 | 66 | 67 | 68 | 69 | 70 | 71 | 72 | 73 | 74 | 75 | 76 | 77 | 78 | 79 | 80 | 81 | 82 | 83 | 84 | 85 | 86 | 87 | 88 | 89 | 90 | 91 | 92 | 93 | 94 | 95 | 96 | 97 | 98 | 99;

export interface VTextProps extends TypographyProps {
    label: string | React.ReactNode;
    type?: "page" | "display" | "headline" | "title" | "subtitle" | "content" | "caption" | "help" | "tiny";
    color?: VColor;
    align?: "right" | "left" | "center" | "inherit" | "justify";
    fontFamily?: string;
    width?: number | string;
    fullWidth?: boolean;
    bold?: boolean;
    light?: boolean;
    underline?: boolean;
    lineThrough?: boolean;
    italic?: boolean;
    wordBreak?: boolean;
    ellipsis?: number;
    className?: string;
    noTranslate?: boolean;
    typing?: boolean;
    speed?: Speed | GranularSpeed | undefined;
    onTypingDone?: () => void;
    onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
    component?: ElementType;
    sx?: SxProps<Theme> | undefined;
}

const VText = (props: VTextProps) => {
    const { type = 'content', label, width, fullWidth = false, bold = false, light = false, underline = false, lineThrough = false, italic = false, wordBreak = false, ellipsis, color = 'text', align, fontFamily, className, noTranslate, sx, typing, speed, onTypingDone, onClick, component = 'p' } = props;
    const [weight, setWeight] = useState<number>(400);
    const typingRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (type === "page" || type === "display" || type === "headline" || type === "title" || type === "subtitle" || type === "tiny") {
            setWeight(700);
        }
    }, [type]);

    return (
        <Typography
            variant={type as TypographyProps['variant']}
            color={VcatColorPalette[color]}
            fontWeight={bold ? 700 : light ? 400 : weight}
            fontFamily={fontFamily}
            className={[ellipsis && ellipsis > 1 ? 'ellipsis-line' : ellipsis === 1 ? 'ellipsis' : null, noTranslate ? 'notranslate' : null, className].join(" ")}
            width={fullWidth && !width ? '100%' : width}
            align={align}
            sx={{
                textDecoration: underline ? 'underline' : lineThrough ? 'line-through' : '',
                fontStyle: italic ? 'italic' : '',
                wordBreak: wordBreak ? 'keep-all' : 'normal',
                '&.ellipsis': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '&.ellipsis-line': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: ellipsis,
                    WebkitBoxOrient: 'vertical',
                },
                ...sx,
            }}
            component={component}
            onClick={onClick}
        >
            {!typing ?
                <>{label}</>
                :
                <TypeAnimation
                    ref={typingRef}
                    speed={speed}
                    cursor={false}
                    className='typing-cursor'
                    sequence={[
                        `${label}`,
                        (el) => {
                            onTypingDone && onTypingDone();
                            el?.classList.remove('typing-cursor');

                        }
                    ]}
                />
            }
        </Typography>
    );
};

export default VText;