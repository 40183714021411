import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
// import EnvStore from "@/EnvStore";
import Utils from "@/infra/Utils";


const link = createHttpLink({
    uri: `${import.meta.env.VITE_API_BASE_URL}/auth/graphql`,
    credentials: "include",
    headers: Utils.buildEnvironmentHeader()

    //credentials:'same-origin'  // api host는 static web 과 domain이 다름. same site 범위에는 포함되지만 host 가 달라 same origin 범위에 포함되지 않음.

});

// const formatDateLink = new ApolloLink((operation, forward) => {
//     return forward(operation).map((response: any) => {
//         if (response && (response.errors || response.error)) {
//             throw new (response);
//         }

//         return response;
//     });
// });

//https://dev.to/tmaximini/accessing-authorization-headers-in-apollo-graphql-client-3b50
const GQLClient = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache",
        },
        query: {
            fetchPolicy: "no-cache",
        },
    },
});

export default GQLClient;
