import GlobalStore from '@/GlobalStore';
import UserService, { BasicUserInfo } from '@/service/UserService';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface UserEmailSearchProp {
    required?: boolean;
    selectedUser: (user: BasicUserInfo, userId: number) => void;
    clearSearchValue: () => void;
    initialUserId?: number;
    initValueLoginUser?: boolean;
    error?: any;
    readonly?: boolean;
    disabled?: boolean;
}

const UserEmailSearch = (props: UserEmailSearchProp) => {
    const gStore = GlobalStore.use();
    const { selectedUser, clearSearchValue, initValueLoginUser, initialUserId, disabled, readonly } = props;
    const [value, setValue] = useState<BasicUserInfo | null>(null);
    const [inputValue, setInputValue] = useState<string>(
        initValueLoginUser ? gStore.user.email : ''
    );
    const [options, setOptions] = useState<readonly BasicUserInfo[]>([]);

    useEffect(() => {
        if (initValueLoginUser || initialUserId) {
            (async () => {
                if (initialUserId) {
                    const result = await UserService.get(initialUserId);
                    setValue(result.data.userForAdmin);
                    selectedUser(result.data.userForAdmin, result.data.userForAdmin.id);
                    setOptions([result.data.userForAdmin]);
                } else if (initValueLoginUser) {
                    const result = await UserService.searchUserByEmail(gStore.user.email);
                    setValue(result.data.list[0]);
                    selectedUser(result.data.list[0], result.data.list[0].id);
                    setOptions([result.data.list[0]]);
                }

            })();
        }
    }, [initValueLoginUser, initialUserId]);

    useEffect(() => {
        if (inputValue === '' || inputValue === undefined) {
            return undefined;
        }
        (async () => {
            const result = await UserService.searchUserByEmail(inputValue);
            if (!result.error && !result.errors) {
                setOptions(result.data.list);
            }
        })();
    }, [inputValue]);

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            filterSelectedOptions
            disabled={disabled}
            readOnly={readonly}
            value={value}
            options={options}
            isOptionEqualToValue={(option: BasicUserInfo, value: BasicUserInfo) => {
                if (option && value) {
                    return option.id === value.id;
                } else if (!option && !value) {
                    return true;
                }
                return false;
            }}
            getOptionLabel={(option: any) => option.email}
            renderInput={(params: any) => (
                <TextField {...params} label="Email" value={inputValue} required={props.required} error={!!props.error} helperText={props.error} placeholder="Search Email" />
            )}
            onInputChange={(event: any, newInputValue: any) => {
                setInputValue(newInputValue);
            }}
            onChange={(event: any, newValue: BasicUserInfo | null) => {
                setValue(newValue);
                if (newValue === null) {
                    clearSearchValue();
                }
                if (newValue) {
                    selectedUser(newValue, newValue.id!);
                }
            }}
            sx={{ width: 235 }}
        />
    );
};

export default UserEmailSearch;
