import VText from '@/common/component/vcat/VText';
import GlobalStore from '@/GlobalStore';
import { Box, Stack, styled } from '@mui/material';
import { shallow } from 'zustand/shallow';

const HomePage = () => {
    const [user] = GlobalStore.use((state) => [state.user], shallow);

    if (!user) {
        return null;
    }
    return (
        <RecordHomeWrap>
            <Stack spacing={3} justifyContent='center' my='auto'>
                <Box className='speech-bubble'>
                    <VText type='subtitle' color='white' label={<>{user.name ? user.name : user.email}님,<br />안녕하세요!</>} />
                </Box>
                <img src="/assets/images/vcat_main.svg" alt="" />
            </Stack>
            <VText color='textTertiary' label='© Pion Corporation All rights reserved.' sx={{ mb: 2 }} />
        </RecordHomeWrap>
    );
};

export default HomePage;

const RecordHomeWrap = styled(Stack)`
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .speech-bubble {
        position: relative;
        background: #292929;
        border-radius: .4em;
        color: white;
        padding: 24px 48px;
        margin-bottom: 36px;
        margin-right: 16px;
    }

    .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top-color: #292929;
        border-bottom: 0;
        margin-left: -8px;
        margin-bottom: -8px;
    }
`;