import GlobalStore from '@/GlobalStore';
import FlagManager from '@/infra/launchdarkly/FlagManager';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { ComponentType, ReactNode, useEffect, useState } from "react";

const CLIENT_SIDE_ID = `${import.meta.env.VITE_LAUNCHDARKLY_SDK_CLIENT ? import.meta.env.VITE_LAUNCHDARKLY_SDK_CLIENT : ""}`;
const SERVICE_NAME = "record-web";
const KIND = "user";

interface LDClientProviderProps {
    children?: ReactNode;
}

const LDClientProvider = ({ children }: LDClientProviderProps) => {
    const { user } = GlobalStore.use();
    const role = user?.authorities;

    const ldClient = useLDClient();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!ldClient) {
            return;
        }

        setContext();

        return () => {
            ldClient.flush();
        };
    }, [ldClient, role]);

    useEffect(() => {
        setVisible(false);
    }, [role]);

    const setContext = async () => {
        const nextContext = {
            anonymous: !!role,
            key: SERVICE_NAME,
            kind: KIND,
            name: SERVICE_NAME,
            role
        };
        try {
            const flags = await ldClient!.identify(nextContext);
            FlagManager.setFlags(flags);
        } finally {
            setVisible(true);
        }
    };

    return visible ? <>{children}</> : null;
};

export default withLDProvider({
    clientSideID: CLIENT_SIDE_ID,
    options: {
        sendEvents: false,
    },
})(LDClientProvider) as ComponentType<LDClientProviderProps>;
