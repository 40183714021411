import useLocationChange from "@/infra/hook/useLocationChange";
import { useModal } from "@ebay/nice-modal-react";

const useCustomModal = () => {
    const modal = useModal();

    useLocationChange(() => {
        modal.reject();
        modal.hide();
    });

    return modal;
};
export default useCustomModal;