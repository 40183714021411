import { VColor, VcatColorPalette } from '@/common/styles/VColor';
import { CSSProperties } from 'react';
import { VIconName, VcatIcons } from '@/common/component/vcat/VIconPaths';

interface VIconProps {
    name: VIconName;
    size?: number;
    color?: VColor;
    shadow?: boolean;
    style?: CSSProperties | undefined;
}

const VIcon_Deprecated = (props: VIconProps) => {
    const {
        name,
        size = 24,
        color = 'text',
        shadow,
        style
    } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={VcatColorPalette[color]}
            fillRule="evenodd"
            clipRule="evenodd"
            style={{
                filter: shadow ? 'drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.25))' : undefined,
                ...style
            }}
        >
            <path d={VcatIcons[name]} />
        </svg>
    );
};

export default VIcon_Deprecated;