
import { VcatColorPalette } from "@/common/styles/VColor";
import useLocationChange from "@/infra/hook/useLocationChange";
import { styled } from '@mui/material';
import { MaterialDesignContent, OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { createWithEqualityFn } from "zustand/traditional";
interface SnackbarParam {
    message: SnackbarMessage;
    options?: OptionsObject;
}
interface IGlobalSnackbar {
    queue: Array<SnackbarParam>;
    show: (message: SnackbarMessage, options?: OptionsObject) => void;
    success: (message: SnackbarMessage, options?: OptionsObject) => void;
    error: (message: SnackbarMessage, options?: OptionsObject) => void;
    warn: (message: SnackbarMessage, options?: OptionsObject) => void;
}

const useSnackbarStore = createWithEqualityFn<IGlobalSnackbar>((set, get) => ({
    queue: [],
    success: (message: SnackbarMessage, options?: OptionsObject) => {
        let copyOptions: OptionsObject = options ? { variant: "success", ...options } : { variant: "success" };
        set({
            queue: [...get().queue, {
                message,
                options: copyOptions
            }]
        });
    },
    error: (message: SnackbarMessage, options?: OptionsObject) => {
        let copyOptions: OptionsObject = options ? { variant: "error", ...options } : { variant: "error" };
        set({
            queue: [...get().queue, {
                message,
                options: copyOptions
            }]
        });
    },
    show: (message: SnackbarMessage, options?: OptionsObject) => {
        set({
            queue: [...get().queue, {
                message,
                options
            }]
        });
    },
    warn: (message: SnackbarMessage, options?: OptionsObject) => {
        let copyOptions: OptionsObject = options ? { variant: "warning", ...options } : { variant: "warning" };
        set({
            queue: [...get().queue, {
                message,
                options: copyOptions
            }]
        });
    },
    info: (message: SnackbarMessage, options?: OptionsObject) => {
        let copyOptions: OptionsObject = options ? { variant: "info", ...options } : { variant: "info" };
        set({
            queue: [...get().queue, {
                message,
                options: copyOptions
            }]
        });
    }
}));
export class GlobalSnackbar {
    public static show(message: SnackbarMessage, options: OptionsObject = { autoHideDuration: 2000 }) {
        useSnackbarStore.getState().show(message, options);
    }
    public static success(message: SnackbarMessage, options: OptionsObject = { autoHideDuration: 2000 }) {
        useSnackbarStore.getState().success(message, options);
    }
    public static error(message: SnackbarMessage, options: OptionsObject = { autoHideDuration: 2000 }) {
        useSnackbarStore.getState().error(message, options);
    }
    public static warn(message: SnackbarMessage, options: OptionsObject = { autoHideDuration: 2000 }) {
        useSnackbarStore.getState().warn(message, options);
    }
}

const SnackbarListener = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackbarQueue = useSnackbarStore(state => state.queue);
    const prevKeys = useRef<Array<SnackbarKey> | null>(null);

    useLocationChange(() => {
        if (prevKeys.current) {
            for (let key of prevKeys.current) {
                try {
                    closeSnackbar(key);
                } catch (e) {
                }
            }
            prevKeys.current = null;
        }
    });
    useEffect(() => {
        if (!prevKeys.current) {
            prevKeys.current = [];
        }
        if (snackbarQueue.length > 0) {
            for (let i = 0; i < snackbarQueue.length; i++) {
                try {
                    const { message, options } = snackbarQueue.pop()!;
                    const key = enqueueSnackbar(message, options);
                    prevKeys.current.push(key);
                } catch (e) {
                }
            }
        }
    }, [snackbarQueue, enqueueSnackbar]);
    return null;
};
export default SnackbarListener;

export const SnackbarStyle = styled(MaterialDesignContent)(() => ({
    borderRadius: 8,
    fontWeight: 700,
    columnGap: 24,
    'div + div': {
        paddingLeft: 0,
        marginRight: 0
    },
    '#notistack-snackbar': {
        flex: '1 0 auto',
        img: {
            marginRight: 8,
            marginBottom: 'auto'
        },
    },
    '&.notistack-MuiContent-default': {
        padding: '8px 20px',
    },
    '&.notistack-MuiContent-success, &.notistack-MuiContent-info, &.notistack-MuiContent-error, &.notistack-MuiContent-warning': {
        padding: '8px 24px 8px 20px',
    },
    '&.notistack-MuiContent-default, &.notistack-MuiContent-success, &.notistack-MuiContent-info, &.notistack-MuiContent-error, &.notistack-MuiContent-warning': {
        backgroundColor: VcatColorPalette.text
    },
}));