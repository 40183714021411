import { AppRoutes } from "@/AppRoutes";
import GlobalStore from "@/GlobalStore";
import FlagManager from "@/infra/launchdarkly/FlagManager";
import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const Router = () => {
    const [user, hasRole] = GlobalStore.use((state) => [state.user, state.hasRole]);

    const router = useMemo(() => {
        const filteredRoutes = user
            ? AppRoutes.map(route => ({
                ...route,
                children: route.children?.filter(child => {
                    if (!!child.featureFlag && !FlagManager.isFlagOn(child.featureFlag)) {
                        return false;
                    }
                    return hasRole(child.roles);
                }),
            }))
            : AppRoutes;

        return createBrowserRouter(filteredRoutes);
    }, [user]);

    return (
        <RouterProvider router={router} />
    );
};

export default Router;
