import EnvStore from "@/EnvStore";
import Utils from "@/infra/Utils";
import axios from "axios";

console.log("EnvStore.BASE_URL", EnvStore.BASE_URL, Utils.buildEnvironmentHeader());
const RecordApiClient = axios.create({
    baseURL: `${EnvStore.BASE_URL}`,
    withCredentials: true,
    headers: Utils.buildEnvironmentHeader()
});

export default RecordApiClient;
