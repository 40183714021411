import VIcon from '@/common/component/vcat/new/VIcon';
import VIcon_Deprecated from '@/common/component/vcat/VIcon_Deprecated';
import { VcatColorPalette } from '@/common/styles/VColor';
import { createTheme, Shadows } from '@mui/material/styles';

interface ColorVariation {
    25: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
}

declare module "@mui/material/styles" {
    interface TypographyVariants {
        page: React.CSSProperties;
        display: React.CSSProperties;
        headline: React.CSSProperties;
        title: React.CSSProperties;
        subtitle: React.CSSProperties;
        content: React.CSSProperties;
        caption: React.CSSProperties;
        help: React.CSSProperties;
        tiny: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        page: React.CSSProperties;
        display: React.CSSProperties;
        headline: React.CSSProperties;
        title: React.CSSProperties;
        subtitle: React.CSSProperties;
        content: React.CSSProperties;
        caption: React.CSSProperties;
        help: React.CSSProperties;
        tiny: React.CSSProperties;
    }
    interface SimplePaletteColorOptions {
        bg?: string;
    }
    interface PaletteColor {
        bg?: string;
    }
    interface TypeText {
        tertiary: string;
        placeholder: string;
    }
    interface Palette {
        red: ColorVariation;
    }
    interface PaletteOptions {
        red?: Partial<ColorVariation>;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        h1: false,
        h2: false,
        h3: false,
        h4: false,
        h5: false,
        h6: false,
        subtitle1: false,
        subtitle2: false,
        body1: false,
        body2: false,
        button: false,
        overline: false,
        inherit: false,
        page: true;
        display: true;
        headline: true;
        title: true;
        subtitle: true;
        content: true;
        caption: true;
        help: true;
        tiny: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        info: false;
    }
}

export const RecordTheme = createTheme({
    typography: {
        fontFamily: "Pretendard, sans-serif",
        htmlFontSize: 16,
        page: {
            fontSize: "2.5rem",
            fontWeight: 700,
            lineHeight: 1.3,
        },
        display: {
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: 1.25,
        },
        headline: {
            fontSize: "1.5rem",
            fontWeight: 700,
            lineHeight: 1.42,
        },
        title: {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: 1.4,
        },
        subtitle: {
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: 1.5,
        },
        content: {
            fontSize: "0.875rem",
            lineHeight: 1.57,
        },
        caption: {
            fontSize: "0.75rem",
            lineHeight: 1.5,
        },
        help: {
            fontSize: "0.6875rem",
            lineHeight: 1.455,
        },
        tiny: {
            fontSize: "0.625rem",
            fontWeight: 700,
            lineHeight: 1.4,
        },
    },
    shape: {
        borderRadius: 4,
    },
    shadows: [...createTheme({}).shadows.map((shadow, i) => (
        i === 1 ? '0px 2px 12px rgba(0, 0, 0, 0.15)' : shadow
    ))] as Shadows,
    palette: {
        primary: {
            main: VcatColorPalette.primary,
            bg: VcatColorPalette.primaryBg,
            light: VcatColorPalette.primaryLight,
            dark: VcatColorPalette.primaryDark,
            contrastText: '#fff',
        },
        secondary: {
            main: VcatColorPalette.secondary,
            bg: VcatColorPalette.secondaryBg,
            light: VcatColorPalette.secondaryLight,
            dark: VcatColorPalette.secondaryDark,
            contrastText: '#fff',
        },
        success: {
            main: VcatColorPalette.success,
            bg: VcatColorPalette.successBg,
            light: VcatColorPalette.successLight,
            dark: VcatColorPalette.successDark,
            contrastText: '#fff',
        },
        warning: {
            main: VcatColorPalette.warning,
            bg: VcatColorPalette.warningBg,
            light: VcatColorPalette.warningLight,
            dark: VcatColorPalette.warningDark,
            contrastText: '#fff',
        },
        error: {
            main: VcatColorPalette.error,
            bg: VcatColorPalette.errorBg,
            light: VcatColorPalette.errorLight,
            dark: VcatColorPalette.errorDark,
            contrastText: '#fff',
        },
        grey: {
            50: VcatColorPalette.g50,
            100: VcatColorPalette.g100,
            200: VcatColorPalette.g200,
            300: VcatColorPalette.g300,
            400: VcatColorPalette.g400,
            500: VcatColorPalette.g500,
            600: VcatColorPalette.g600,
            700: VcatColorPalette.g700,
            800: VcatColorPalette.g800,
            900: VcatColorPalette.g900,
        },
        red: {
            25: VcatColorPalette.r25,
            50: VcatColorPalette.r50,
            100: VcatColorPalette.r100,
            200: VcatColorPalette.r200,
            300: VcatColorPalette.r300,
            400: VcatColorPalette.r400,
            500: VcatColorPalette.r500,
            600: VcatColorPalette.r600,
            700: VcatColorPalette.r700,
            800: VcatColorPalette.r800,
            900: VcatColorPalette.r900,
        },
        text: {
            primary: VcatColorPalette.text,
            secondary: VcatColorPalette.textSecondary,
            tertiary: VcatColorPalette.textTertiary,
            placeholder: VcatColorPalette.textPlaceholder,
            disabled: VcatColorPalette.textDisabled,
        },
        action: {
            disabled: VcatColorPalette.textDisabled
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variant: 'content',
                variantMapping: {
                    page: 'p',
                    display: 'p',
                    headline: 'p',
                    title: 'p',
                    subtitle: 'p',
                    content: 'p',
                    caption: 'p',
                    help: 'p',
                    tiny: 'p',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: VcatColorPalette.g200
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                color: 'inherit',
                elevation: 0,
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                size: "small",
                fullWidth: false,
            },
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 700,
                    lineHeight: 1,
                    minWidth: 32,
                    textTransform: 'inherit',
                    '&.MuiButton-sizeLarge': {
                        height: 48,
                        padding: '0 20px',
                    },
                    '&.MuiButton-sizeMedium': {
                        height: 40,
                        fontSize: '0.875rem',
                        padding: '0 16px',
                    },
                    '&.MuiButton-sizeSmall': {
                        height: 32,
                        fontSize: '0.813rem',
                        padding: '0 12px',
                    },
                    '&.MuiButton-outlinedPrimary': {
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 145, 234, 0.1)',
                            borderColor: 'rgba(0, 145, 234, 0.5)',
                        },
                    },
                    '&.MuiButtonContainedPrimary': {
                        '&:hover': {
                            backgroundColor: VcatColorPalette.primaryDark,
                        },
                    },
                },
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                        borderRadius: 4
                    }
                },
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiToggleButton: {
            defaultProps: {
                size: 'small',
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    minWidth: 32,
                    minHeight: 32,
                    padding: '5px',
                    fontSize: '0.75rem',
                    lineHeight: 1,
                    borderRadius: 6,
                    textTransform: 'inherit',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    '&.MuiFab-primary': {
                        '&:hover': {
                            backgroundColor: VcatColorPalette.primaryDark,
                        },
                    }
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                fullWidth: false,
            },
            styleOverrides: {
                root: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    border: `1px solid ${VcatColorPalette.g100}`,
                    backgroundColor: VcatColorPalette.g100,
                    borderRadius: '6px',
                    padding: 0,
                    '&::before, &::after': {
                        display: 'none'
                    },
                    '&:hover': {
                        borderColor: VcatColorPalette.g300,
                        boxShadow: `0 0 0 4px ${VcatColorPalette.primaryBg}`,
                        backgroundColor: VcatColorPalette.g100,
                    },
                    '&.Mui-focused': {
                        borderColor: VcatColorPalette.primary,
                        boxShadow: `0 0 0 4px ${VcatColorPalette.primaryBg}`,
                        backgroundColor: VcatColorPalette.g100,
                        '&.MuiInputBase-readOnly': {
                            borderColor: VcatColorPalette.g300,
                            boxShadow: 'none'
                        },
                    },
                    '&.Mui-error': {
                        borderColor: VcatColorPalette.error,
                        boxShadow: `0 0 0 4px ${VcatColorPalette.r25}`,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: VcatColorPalette.g100,
                        borderColor: VcatColorPalette.g200,
                        boxShadow: 'none'
                    },
                    '&.MuiInputBase-sizeSmall': {
                        fontSize: '0.875rem',
                    },
                    '.MuiFilledInput-input': {
                        height: 40,
                        padding: '0 12px',
                        caretColor: VcatColorPalette.primary,
                        '&.MuiInputBase-inputSizeSmall': {
                            height: 32,
                            padding: '0 12px',
                        },
                        '&::placeholder': {
                            color: VcatColorPalette.textPlaceholder,
                            opacity: 1,
                        },
                    },
                    '.MuiInputAdornment-root': {
                        height: '100%',
                        '&.MuiInputAdornment-positionStart': {
                            marginLeft: 12,
                        },
                        '&.MuiInputAdornment-positionEnd': {
                            marginLeft: 0,
                            marginRight: 12,
                            '> :not(style) + :not(style)': {
                                marginLeft: 4,
                            },
                        },
                        '+ .MuiFilledInput-input': {
                            padding: 0
                        }
                    },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    padding: 0,
                    fieldset: {
                        border: `1px solid ${VcatColorPalette.g300}`,
                        borderRadius: 6,
                        transition: 'all 0.15s ease',
                        legend: {
                            maxWidth: '100%'
                        }
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: VcatColorPalette.g300,
                            boxShadow: `0 0 0 4px ${VcatColorPalette.primaryBg}`,
                        },
                        '&.MuiInputBase-readOnly': {
                            fieldset: {
                                borderColor: VcatColorPalette.g300,
                                boxShadow: 'none'
                            },
                        },
                    },
                    '&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderWidth: 1,
                            borderColor: VcatColorPalette.primary,
                            boxShadow: `0 0 0 4px ${VcatColorPalette.primaryBg}`,
                        },
                        '&.MuiInputBase-readOnly': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: VcatColorPalette.g300,
                                boxShadow: 'none'
                            },
                        },
                    },
                    '&.Mui-error': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: VcatColorPalette.error,
                            boxShadow: `0 0 0 4px ${VcatColorPalette.r25}`,
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: VcatColorPalette.g50,
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: VcatColorPalette.g200,
                            boxShadow: 'none'
                        },
                    },
                    '&.MuiInputBase-sizeSmall': {
                        fontSize: '0.875rem',
                    },
                    '&.MuiInputBase-readOnly': {
                        fieldset: {
                            boxShadow: 'none'
                        },
                    },
                    '.MuiOutlinedInput-input': {
                        height: 40,
                        padding: '0 12px',
                        caretColor: VcatColorPalette.primary,
                        '&.MuiInputBase-inputSizeSmall': {
                            height: 32,
                            padding: '0 12px',
                        },
                        '&::placeholder': {
                            color: VcatColorPalette.textPlaceholder,
                            opacity: 1,
                        },
                        '&.MuiInputBase-inputMultiline': {
                            padding: '0 12px 0 0',
                        },
                        '&:not(.MuiInputBase-inputMultiline)': {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    },
                    '.MuiInputAdornment-root': {
                        height: '100%',
                        '&.MuiInputAdornment-positionStart': {
                            marginLeft: 12,
                        },
                        '&.MuiInputAdornment-positionEnd': {
                            marginLeft: 0,
                            marginRight: 12,
                            '> :not(style) + :not(style)': {
                                marginLeft: 4,
                            },
                        },
                        '+ .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                fullWidth: false,
                FormHelperTextProps: {
                    component: 'div',
                }
            },
            styleOverrides: {
                root: {
                    '.MuiFormLabel-root': {
                        '.MuiFormLabel-asterisk': {
                            color: VcatColorPalette.error,
                            marginLeft: 2,
                        },
                    },
                    '.MuiFormHelperText-root': {
                        marginTop: 4,
                        marginLeft: 0,
                        marginRight: 0,
                        lineHeight: 1,
                        '&.Mui-disabled': {
                            color: VcatColorPalette.textSecondary,
                        },
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                MenuProps: {
                    sx: {
                        '.MuiPaper-root': {
                            marginTop: 0.5,
                            borderRadius: 1.5,
                            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
                            ul: {
                                padding: 0.5,
                                li: {
                                    padding: '8px 32px 8px 8px',
                                    minHeight: 40,
                                    borderRadius: 1.5,
                                    '&:hover': {
                                        backgroundColor: VcatColorPalette.g100
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: 'transparent',
                                        color: VcatColorPalette.primary,
                                        '&:after': {
                                            content: '""',
                                            display: 'block',
                                            width: 16,
                                            height: 16,
                                            position: 'absolute',
                                            top: 12,
                                            right: 8,
                                            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgOC4wODMzM0w3LjExMTExIDExTDEyIDUuNSIgc3Ryb2tlPSIjMDA1RUVBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=)'
                                        },
                                        '&:hover': {
                                            backgroundColor: VcatColorPalette.g100
                                        },
                                    }
                                }
                            }
                        }
                    }
                }
            },
            styleOverrides: {
                select: {
                    display: 'flex',
                    alignItems: 'center',
                    '~ svg': {
                        right: 11
                    },
                    '&.MuiInputBase-inputSizeSmall': {
                        '~ svg': {
                            right: 7
                        },
                    },
                    '&[aria-expanded="true"]': {
                        '~ svg': {
                            transform: 'rotate(180deg)'
                        }
                    },
                    '&.Mui-disabled': {
                        'svg': {
                            fill: VcatColorPalette.textDisabled
                        },
                    }
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                size: 'medium',
                slotProps: {
                    paper: {
                        sx: {
                            marginTop: 0.5,
                            borderRadius: 1.5,
                            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',
                            '.MuiAutocomplete-noOptions': {
                                padding: 1.5,
                                minHeight: 40,
                                fontSize: '0.875rem',
                                color: VcatColorPalette.g500,
                            },
                            ul: {
                                padding: 0.5,
                                li: {
                                    padding: 1,
                                    minHeight: 40,
                                    borderRadius: 1.5,
                                    fontSize: '0.875rem',
                                    '&.Mui-focused': {
                                        color: VcatColorPalette.primary,
                                        backgroundColor: VcatColorPalette.g100
                                    }
                                }
                            }
                        }
                    },
                    popupIndicator: {
                        sx: {
                            padding: 0
                        }
                    },
                    clearIndicator: {
                        sx: {
                            padding: 0,
                            marginRight: 0.5
                        }
                    }
                },
                noOptionsText: '일치하는 항목 없음',
                popupIcon: <VIcon name="dropdownDown" color="textTertiary" size={16} />,
                clearIcon: <VIcon name='clear' color="textTertiary" size={16} />
            },
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-root': {
                        paddingTop: 4,
                        paddingLeft: 4,
                        paddingBottom: 4,
                        '.MuiAutocomplete-input': {
                            height: 32,
                            padding: '0 0 0 8px',
                        },
                        '&.MuiInputBase-sizeSmall': {
                            paddingTop: 5,
                            paddingLeft: 5,
                            paddingBottom: 5,
                            '.MuiAutocomplete-input': {
                                height: 24,
                                padding: '0 0 0 8px',
                            }
                        },
                        '.MuiAutocomplete-endAdornment': {
                            top: '50%',
                            right: 10,
                            transform: 'translateY(-50%)',
                            display: 'flex',
                        },
                        fieldset: {
                            legend: {
                                maxWidth: '100%'
                            }
                        }
                    },
                    '.MuiChip-root': {
                        margin: 4,
                        '&:first-of-type': {
                            marginLeft: 4
                        }
                    }
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: "dense",
            },
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    "&.MuiInputBase-sizeSmall": {
                        fontSize: "0.8125rem",
                        "& .MuiInputBase-inputSizeSmall": {
                            height: 18,
                            padding: '8px 14px',
                        },
                        "& .MuiInputAdornment-positionStart + .MuiInputBase-inputSizeSmall": {
                            paddingLeft: 0,
                        },
                        "& .MuiInputAdornment-positionEnd + .MuiInputBase-inputSizeSmall": {
                            paddingRight: 0,
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            },
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    fontWeight: 700,
                    color: VcatColorPalette.textTertiary
                },
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                slotProps: {
                    typography: {
                        fontSize: '0.875rem',
                    }
                }
            },
            styleOverrides: {
                root: {
                    marginLeft: -8,
                    '&:hover': {
                        '.MuiCheckbox-root, .MuiRadio-root': {
                            div: {
                                boxShadow: `0 0 0 2px ${VcatColorPalette.p25}`
                            },
                            '&.Mui-disabled': {
                                div: {
                                    boxShadow: 'none'
                                },
                            }
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                size: "small",
                icon: <div className='off' />,
                checkedIcon: <div className='on'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.4983 4.43945C12.8079 4.71464 12.8357 5.18869 12.5606 5.49828L7.67167 10.9983C7.53723 11.1495 7.34738 11.24 7.14523 11.2492C6.94308 11.2584 6.74579 11.1856 6.59816 11.0472L3.48705 8.13049C3.18486 7.8472 3.16955 7.37257 3.45285 7.07039C3.73615 6.7682 4.21077 6.75289 4.51296 7.03619L7.06222 9.42612L11.4394 4.50174C11.7146 4.19215 12.1887 4.16426 12.4983 4.43945Z" fill="white" />
                    </svg>
                </div>,
                indeterminateIcon: <div className='indeterminate'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H12C12.4142 7.25 12.75 7.58579 12.75 8C12.75 8.41421 12.4142 8.75 12 8.75H4C3.58579 8.75 3.25 8.41421 3.25 8Z" fill="white" />
                    </svg>
                </div>
            },
            styleOverrides: {
                root: {
                    padding: 8,
                    div: {
                        width: 16,
                        height: 16,
                        borderRadius: 4,
                        overflow: 'hidden',
                        display: 'flex',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                        '&.off': {
                            border: `1.5px solid ${VcatColorPalette.g300}`
                        },
                        '&.on, &.indeterminate': {
                            backgroundColor: VcatColorPalette.primary
                        }
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-disabled': {
                        '.off': {
                            borderColor: VcatColorPalette.g200,
                        },
                        '&.Mui-checked': {
                            '.on': {
                                backgroundColor: VcatColorPalette.g400
                            }
                        },
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: "small",
                icon: <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle cx="8" cy="8" r="7.25" fill="white" stroke={VcatColorPalette.g300} strokeWidth="1.5" />
                    </svg>
                </div>,
                checkedIcon: <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle className='line' cx="8" cy="8" r="7.25" fill="white" stroke={VcatColorPalette.g300} strokeWidth="1.5" />
                        <circle className='dot' cx="8" cy="8" r="4" fill={VcatColorPalette.primary} />
                    </svg>
                </div>,
            },
            styleOverrides: {
                root: {
                    padding: 8,
                    div: {
                        width: 16,
                        height: 16,
                        borderRadius: 8,
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-disabled': {
                        svg: {
                            circle: {
                                '&.line': {
                                    stroke: VcatColorPalette.g200,
                                },
                                '&.dot': {
                                    fill: VcatColorPalette.g200,
                                }
                            },
                        },
                    },
                },
            },
        },
        MuiSlider: {
            defaultProps: {
                valueLabelDisplay: 'auto'
            },
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        '.MuiSlider-rail': {
                            backgroundColor: VcatColorPalette.g100
                        },
                        '.MuiSlider-track': {
                            backgroundColor: VcatColorPalette.g200
                        }
                    }
                },
                rail: {
                    backgroundColor: VcatColorPalette.g400
                },
                track: {
                    border: 0
                },
                thumb: {
                    width: 24,
                    height: 24,
                    backgroundColor: 'white',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                    '&:before': {
                        boxShadow: 'none'
                    },
                },
                thumbSizeSmall: {
                    width: 16,
                    height: 16,
                },
                valueLabel: {
                    top: -12,
                    backgroundColor: VcatColorPalette.primary,
                    padding: '0 6px',
                    borderRadius: 4,
                    fontWeight: 700,
                    fontSize: '0.75rem',
                    lineHeight: 1.33,
                    '&:before': {
                        display: 'none'
                    }
                },
                markLabel: {
                    fontWeight: 700,
                    fontSize: '0.75rem',
                    color: VcatColorPalette.textTertiary,
                    transform: 'translateX(-100%)',
                    '&[data-index="0"]': {
                        transform: 'translateX(0)'
                    }
                },
                mark: {
                    display: 'none'
                },
                sizeSmall: {
                    height: 4
                }
            }
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: "dense",
            },
        },
        MuiMenu: {
            defaultProps: {
                elevation: 1
            },
            styleOverrides: {
                paper: {
                    minWidth: 120,
                    marginTop: 4,
                    '&.MuiPaper-root': {
                        borderRadius: 6,
                    },
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    minHeight: 40,
                    borderRadius: 6,
                    '&:hover': {
                        backgroundColor: VcatColorPalette.g100
                    },
                    '.MuiListItemIcon-root': {
                        minWidth: 'auto',
                        marginRight: 8
                    },
                    '.MuiBox-root': {
                        alignItems: 'center'
                    },
                    '&.Mui-disabled': {
                        opacity: 1,
                        color: VcatColorPalette.textDisabled
                    }
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 4,
                    li: {
                        padding: 8,
                    }
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: "8px 24px",
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: "40px",
                },
            },
        },
        MuiChip: {
            defaultProps: {
                size: "small",
                variant: "filled",
            },
        },
        MuiTab: {
            defaultProps: {
                iconPosition: 'start',
            },
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    minHeight: 48,
                    color: VcatColorPalette.textTertiary,
                    fontWeight: 700,
                    lineHeight: '1.25rem',
                    textTransform: 'capitalize',
                    opacity: 1,
                    "&:last-of-type": {
                        marginRight: 0,
                    },
                    '&:hover': {
                        color: VcatColorPalette.textSecondary,
                        svg: {
                            fill: VcatColorPalette.textSecondary,
                            '&.error': {
                                fill: VcatColorPalette.error,
                            }
                        }
                    },
                    '&.Mui-selected': {
                        color: VcatColorPalette.primary,
                        svg: {
                            fill: VcatColorPalette.primary,
                            '&.error': {
                                fill: VcatColorPalette.error,
                            }
                        },
                    },
                    '&.Mui-disabled': {
                        color: VcatColorPalette.textDisabled
                    }
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 48,
                    boxShadow: `inset 0 -1px 0 0 ${VcatColorPalette.g200}`,
                    "&.none-border": {
                        borderBottom: 0,
                    },
                    ".MuiTab-root": {
                        "&.small": {
                            fontSize: '0.875rem',
                            minHeight: 40,
                            padding: 8,
                            marginRight: 8,
                        },
                    },
                },
                indicator: {
                    height: 3,
                },
                vertical: {
                    boxShadow: `inset -1px 0 0 0 ${VcatColorPalette.g200}`,
                    '.MuiTab-root': {
                        marginRight: 0,
                        padding: '8px 24px 8px 8px',
                        justifyContent: 'flex-start'
                    },
                    '.MuiTabs-indicator': {
                        width: 3,
                    }
                },
            },
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                square: true,
            },
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <VIcon_Deprecated name='dropdownDown' size={16} />
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    color: VcatColorPalette.textSecondary
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '16px 24px 8px',
                    '.MuiCardHeaderContent': {
                        '.MuiTypography-root': {
                            fontSize: '1rem',
                            fontWeight: 700,
                        },
                    },
                    '.MuiCardHeader-action': {
                        alignSelf: 'center',
                        margin: 0,
                    }
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '8px 24px 16px',
                }
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: "top",
            },
            styleOverrides: {
                tooltip: {
                    fontSize: "0.75rem",
                    backgroundColor: VcatColorPalette.text,
                    borderRadius: 6,
                    padding: '8px 12px',
                },
                arrow: {
                    color: VcatColorPalette.text,
                },
                popper: {
                    '&[data-popper-placement*="top"]': {
                        '.MuiTooltip-tooltip': {
                            marginBottom: 10
                        },
                    },
                    '&[data-popper-placement*="bottom"]': {
                        '.MuiTooltip-tooltip': {
                            marginTop: 10
                        },
                    },
                    '&[data-popper-placement*="left"]': {
                        '.MuiTooltip-tooltip': {
                            marginRight: 10
                        },
                    },
                    '&[data-popper-placement*="right"]': {
                        '.MuiTooltip-tooltip': {
                            marginLeft: 10
                        },
                    },
                    '.MuiTooltip-tooltip': {
                        fontSize: "0.75rem",
                        backgroundColor: VcatColorPalette.text,
                        padding: '8px 12px',
                    }
                }
            },
        },
        MuiDialog: {
            defaultProps: {
                maxWidth: "xs",
                fullWidth: true,
            },
            styleOverrides: {
                paper: {
                    borderRadius: 8,
                    "> button": {
                        position: "absolute",
                        right: 26,
                        top: 26,
                        zIndex: 10
                    },
                },
                paperWidthXs: {
                    maxWidth: 480
                },
                paperWidthSm: {
                    maxWidth: 600
                },
                paperWidthMd: {
                    maxWidth: 800
                },
                paperWidthLg: {
                    maxWidth: 1080
                },
                paperWidthXl: {
                    maxWidth: 1200
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "28px 28px 16px",
                    fontWeight: 400,
                    '+ .MuiDialogContent-root': {
                        paddingTop: '4px !important',
                    }
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "4px 28px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 28,
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    "& .MuiImageListItemBar-titleWrap": {
                        padding: "16px 4px 24px",
                    },
                },
            },
        },
        MuiSkeleton: {
            defaultProps: {
                animation: 'wave',
                variant: 'rounded'
            },
            styleOverrides: {
                root: {
                    backgroundColor: VcatColorPalette.g100
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar": {
                        width: 14,
                        height: 14,
                        backgroundColor: "transparent",
                    },
                    "& *::-webkit-scrollbar": {
                        width: 14,
                        height: 14,
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        backgroundColor: VcatColorPalette.textTertiary,
                        backgroundClip: "padding-box",
                        border: "4px solid transparent",
                        borderRadius: 8,
                        transition: "all 0.3s ease",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
});