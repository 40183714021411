import { Pagable } from "@/infra/CommonType";
import GQLClient from "@/infra/GQLClient";
import GqlHelper from "@/infra/GqlHelper";
import Utils from "@/infra/Utils";
import { gql } from "@apollo/client";

export enum FontStatus {
    REGISTERED = "registered",
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum WebFontType {
    FILE = "FILE",
    LINK = "LINK",
}

export interface IFontLanguage {
    code: string;
    title: string;
}

export interface IFont {
    id: number;
    title: string;
    orderFamily: string;
    fontUid: string;
    fontEngName: string;
    fontFamily: string;
    weight: number;
    aeFontName: string;
    bucket: string;
    fontPath: string;
    titleThumbImagePath: string;
    webFontType: WebFontType;
    webFontLink: string;
    webFontPath: string;
    language: Array<string>;
    status: FontStatus;
    alternateFontId: number | null;
    hanjaYn: boolean;
    customYn: boolean;
    createdAt: number;
    modifiedAt: number;
}

export interface FontUpdate {
    id: number;
    title: string;
    orderFamily: string;
    language: Array<string>;
    alternateFontId: number | null;
    webFontType: string;
    webFontPath: string;
    webFontLink: string;
    weight: number;
    hanjaYn: boolean;
    customYn: boolean;
    newWebFontFile: {
        bucket: string;
        path: string;
        fileName: string;
    };
}

export interface FontStatusUpdate {
    id: number;
    status: string;
}


export interface IUserFavoriteFont {
    userId: number;
    fontId: number;
}
class FontService {
    static readonly FILE_TYPE_PREFIX = "vcat-";
    static readonly LINK_TYPE_PREFIX = "font-";
    private static _fontList: Array<IFont> = [];
    private static _fontLanguages: Array<IFontLanguage> = [];
    static readonly ALTERNATE_FONT_ID_MAP: { [key: string]: IFont; } = {};
    static readonly FONT_ID_MAP: { [key: string]: IFont; } = {};
    static readonly LOADED_FONT_MAP: { [key: string]: { fontFamily: string, fontWeight: number | undefined; }; } = {};
    static readonly LANGUAGE_FONT_MAP: { [key: string]: IFont[]; } = {};

    public static readonly FONT_FIELDS = gql`
      fragment fontFields on Font {
            id
            title
            fontUid
            fontEngName
            fontFamily
            orderFamily
            weight
            aeFontName
            fontPath
            titleThumbImagePath
            webFontType
            webFontLink
            webFontPath
            language
            status
            alternateFontId
            hanjaYn
            customYn
            createdAt
            modifiedAt
        }
    `;

    public static readonly FONTS = gql`
        query fontsForAdmin(,$page: Int, $size: Int) {
            page: fontsForAdmin(page: $page, size: $size) {
                ${GqlHelper.pageableGql(`
                    ...fontFields
                `)}
            }
        }
        ${FontService.FONT_FIELDS}
    `;

    public static readonly CUSTOM_FONTS = gql`
    query customFontsForAdmin($page: Int, $size: Int) {
        customFontsForAdmin(page: $page, size: $size) {
            ...fontFields
        }
    }
    ${FontService.FONT_FIELDS}
    `;


    public static readonly TEAM_CUSTOM_FONT_IDS = gql`
    query teamCustomFontIdsForAdmin($teamId : Long!) {
        teamCustomFontIdsForAdmin(teamId : $teamId) 
    }
    `;


    public static readonly UPDATE_FONT_INFO = gql`
        mutation updateFontInfo($updateInfo: FontUpdate) {
            updateFontInfo(updateInfo: $updateInfo) {
                id 
                title
                fontUid 
                fontFamily
                aeFontName
                fontPath
                titleThumbImagePath
                webFontType
                webFontLink
                webFontPath
                weight
                language
                customYn
                status
           }
        }
    `;

    public static readonly REGISTER_FONTS = gql`
        mutation registerFontForAdmin($input : FontRegisterInput!) {
            registerFontForAdmin(input : $input) {
                ...fontFields
            }
        }
        ${FontService.FONT_FIELDS}
    `;

    public static readonly UPDATE_FONT = gql`
        mutation updateFontForAdmin($input : FontUpdateInput!) {
            updateFontForAdmin(input : $input) {
                ...fontFields
            }
        }
        ${FontService.FONT_FIELDS}
    `;

    public static readonly UPDATE_FONT_STATUS = gql`
        mutation updateFontStatusForAdmin($input : FontStatusChangeInput) {
            updateFontStatusForAdmin(input : $input) {
                ...fontFields
            }
        }
        ${FontService.FONT_FIELDS}
    `;

    public static readonly ALL_FONTS = gql`
        query allFonts {
            list: allFontsForAdmin {
                ...fontFields
            }
        }
        ${FontService.FONT_FIELDS}
    `;

    public static readonly FONT_LANGUAGES = gql`
        query fontLanguages {
            languages: fontLanguages {
                code
                title
            }
        }
    `;

    public static readonly USER_FAVORITE_FONTS = gql`
        query userFavoriteFonts {
            favorite: userFavoriteFonts {
                fontId
            }
        }
    `;

    public static readonly CREATE_USER_FAVORITE_FONT = gql`
        mutation createUserFavoriteFont($fontId: Long) {
            createUserFavoriteFont(fontId: $fontId)
        }
    `;

    public static readonly REMOVE_USER_FAVORITE_FONT = gql`
        mutation removeUserFavoriteFont($fontId: Long) {
            removeUserFavoriteFont(fontId: $fontId)
        }
    `;


    public static readonly ALLOW_CUSTOM_FONT = gql`
    mutation allowCustomFontForAdmin($teamId: Long!,$fontId: Long!) {
        allowCustomFontForAdmin(teamId : $teamId , fontId: $fontId)
    }
`;

    public static readonly DISALLOW_CUSTOM_FONT = gql`
    mutation disallowCustomFontForAdmin($teamId: Long!,$fontId: Long!) {
        disallowCustomFontForAdmin(teamId : $teamId , fontId: $fontId)
    }
`;

    public static readonly removeUserFavoriteFont = async (fontId: number) => {
        const resp = await GQLClient.mutate<
            { removeUserFavoriteFont: boolean; },
            { fontId: number; }
        >({
            mutation: FontService.REMOVE_USER_FAVORITE_FONT,
            variables: {
                fontId,
            },
        });

        return resp;
    };

    public static readonly createUserFavoriteFont = async (fontId: number) => {
        const resp = await GQLClient.mutate<
            { createUserFavoriteFont: boolean; },
            { fontId: number; }
        >({
            mutation: FontService.CREATE_USER_FAVORITE_FONT,
            variables: {
                fontId,
            },
        });

        return resp;
    };

    public static readonly fonts = async (page: number = 0, size: number = 2000) => {
        return (await GQLClient.query<{ page: Pagable<IFont>; }, { page: number; size: number; }>({
            query: FontService.FONTS,
            variables: {
                page: page,
                size: size,
            },
        })).data.page;
    };

    public static readonly customfonts = async (page: number = 0, size: number = 2000) => {
        return (await GQLClient.query<{ customFontsForAdmin: Array<IFont>; }, { page: number; size: number; }>({
            query: FontService.CUSTOM_FONTS,
            variables: {
                page: page,
                size: size,
            },
        })).data.customFontsForAdmin;
    };

    public static readonly teamCustomfontIds = async (teamId: number) => {
        return (await GQLClient.query<{ teamCustomFontIdsForAdmin: Array<number>; }, { teamId: number; }>({
            query: FontService.TEAM_CUSTOM_FONT_IDS,
            variables: {
                teamId
            },
        })).data.teamCustomFontIdsForAdmin;
    };

    public static readonly allowCustomFontForAdmin = async (teamId: number, fontId: number) => {
        const resp = await GQLClient.mutate<{ allowCustomFontForAdmin: boolean; }, { teamId: number, fontId: number; }>({
            mutation: FontService.ALLOW_CUSTOM_FONT,
            variables: {
                teamId, fontId
            }
        });
        return resp.data!.allowCustomFontForAdmin;
    };

    public static readonly disallowCustomFontForAdmin = async (teamId: number, fontId: number) => {
        const resp = await GQLClient.mutate<{ disallowCustomFontForAdmin: boolean; }, { teamId: number, fontId: number; }>({
            mutation: FontService.DISALLOW_CUSTOM_FONT,
            variables: {
                teamId, fontId
            }
        });
        return resp.data!.disallowCustomFontForAdmin;
    };

    public static readonly updateFont = async (input: FontUpdate) => {
        const resp = await GQLClient.mutate<{ updateFontForAdmin: IFont; }, { input: FontUpdate; }>({
            mutation: FontService.UPDATE_FONT,
            variables: {
                input,
            },
        });

        return resp.data!.updateFontForAdmin;
    };

    public static readonly updateFontStatus = async (input: FontStatusUpdate) => {
        const resp = await GQLClient.mutate<{ updateFontStatusForAdmin: IFont; }, { input: FontStatusUpdate; }>({
            mutation: FontService.UPDATE_FONT_STATUS,
            variables: {
                input,
            },
        });

        return resp.data!.updateFontStatusForAdmin;
    };

    public static readonly userFavoriteFonts = async () => {
        const resp = await GQLClient.query<{ favorite: Array<IUserFavoriteFont>; }>({
            query: FontService.USER_FAVORITE_FONTS,
        });
        return resp.data.favorite.map(f => f.fontId);
    };

    public static clear = () => {
        FontService._fontList = [];
        FontService._fontLanguages = [];
    };

    public static load = async () => {
        await FontService.initFontLanguages();
        await FontService.initFontList();
        FontService.initLanguageFontMap();
    };

    private static async initFontLanguages() {
        if (!FontService._fontLanguages || FontService._fontLanguages.length === 0) {
            // const resp = await VcatCache.Cachable('font:lang', async () => {
            //     return await GQLClient.query<{ languages: IFontLanguage[]; }>({
            //         query: FontService.FONT_LANGUAGES,
            //     });
            // })();
            const resp = await GQLClient.query<{ languages: IFontLanguage[]; }>({
                query: FontService.FONT_LANGUAGES,
            });

            FontService._fontLanguages = resp.data.languages;
        }
    }

    public static getFontLanguage = async () => {
        return (await GQLClient.query<{ languages: IFontLanguage[]; }>({
            query: FontService.FONT_LANGUAGES,
        })).data.languages;
    };

    private static async initFontList() {
        if (!FontService._fontList || FontService._fontList.length === 0) {
            // const resp = await VcatCache.Cachable('font:list', async () => await GQLClient.query<{ list: Array<IFont>; }>({
            //     query: FontService.ALL_FONTS,
            // }))();
            const resp = await GQLClient.query<{ list: Array<IFont>; }>({
                query: FontService.ALL_FONTS,
            });
            const allFonts = resp.data.list;
            FontService._fontList = allFonts.filter(f => f.status === FontStatus.ACTIVE);
            allFonts.forEach(f => {
                const { status, fontUid, fontEngName, alternateFontId, aeFontName } = f;
                if (status === FontStatus.ACTIVE) {
                    FontService.FONT_ID_MAP[fontUid] = f;
                    FontService.FONT_ID_MAP[fontEngName] = f;
                }
                else if (status === FontStatus.INACTIVE) {
                    if (alternateFontId) {
                        const alternateFont = FontService._fontList.find(ff => ff.id === alternateFontId);
                        if (alternateFont) {
                            FontService.ALTERNATE_FONT_ID_MAP[fontUid] = alternateFont;
                            FontService.ALTERNATE_FONT_ID_MAP[fontEngName] = alternateFont;
                        }
                    }
                }
            });
        }
    }

    private static initLanguageFontMap() {
        FontService._fontLanguages.forEach(l => {
            FontService.LANGUAGE_FONT_MAP[l.code] = [];
        });

        FontService._fontList.forEach(font => {
            font.language.forEach(lang => {
                if (!FontService.LANGUAGE_FONT_MAP[lang]) {
                    FontService.LANGUAGE_FONT_MAP[lang] = [];
                }
                FontService.LANGUAGE_FONT_MAP[lang].push(font);
            });
        });
    }

    public static get languageFontMap() {
        return FontService.LANGUAGE_FONT_MAP;
    }

    public static get fontList() {
        return FontService._fontList;
    }

    public static findFontByFontFamily(fontFamily: string | undefined) {
        if (!fontFamily) return undefined;
        const re = new RegExp(`^(${FontService.FILE_TYPE_PREFIX})?(.+)`);
        const fontUid = fontFamily!.replace(re, '$2');
        return FontService.FONT_ID_MAP[fontUid];
    }

    public static getFontLanguageTitle(code: string) {
        return FontService._fontLanguages.find(e => e.code === code)?.title;
    }

    public static getAlternateFontId(fontUid: string) {
        if (fontUid === 'NotoSansCJKkr-Bold') {
            return 'Noto Sans KR Bold';
        }
        const font = FontService.ALTERNATE_FONT_ID_MAP[fontUid];
        if (font) {
            return font.fontUid;
        }
    }

    public static findFont(fontUid: string): IFont | undefined {
        return FontService.FONT_ID_MAP[fontUid];
    }

    public static linkFont = async (font: IFont): Promise<{ fontFamily: string, fontWeight?: number; }> => {
        const { fontUid, fontFamily, webFontLink, weight } = font;
        const id = `${FontService.LINK_TYPE_PREFIX}${fontUid}`;
        // console.log("Link Font Load", font, fontFamily);
        return new Promise((res, rej) => {
            let _fontFamily = fontFamily;
            if (webFontLink.indexOf('https://fonts.googleapis.com') >= 0) {
                try {
                    const familyParam = new URL(webFontLink).searchParams.get('family')!;
                    if (familyParam) {
                        // console.log("family param ", familyParam);
                        _fontFamily = familyParam.split(':')[0];
                        // console.log("Link Font Load From param", font, fontFamily, familyParam);
                    }
                } catch (e) {

                }

            }
            if (!FontService.LOADED_FONT_MAP[fontUid]) {
                FontService.LOADED_FONT_MAP[fontUid] = { fontFamily: _fontFamily, fontWeight: weight || 400 };
                let link = document.createElement('link');
                link.id = id;
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = webFontLink;
                link.onerror = () => {
                    rej('web font link error');
                };

                link.onload = () => {
                    res({ fontFamily: _fontFamily, fontWeight: weight || 400 });
                };

                document.head.appendChild(link);
            } else {
                res({ fontFamily: _fontFamily, fontWeight: weight || 400 });
            }

        });

    };

    public static buildFileFontFamily = (font: IFont) => {
        return `${FontService.FILE_TYPE_PREFIX}${font.fontUid}`;
    };
    public static async fontLoad(font: IFont) {
        const fontUid = font.fontUid;
        const fontFamily = `${FontService.buildFileFontFamily(font)}`;

        if (!FontService.LOADED_FONT_MAP[fontUid]) {
            const source = `url('${Utils.getResourceUrl(font.webFontPath)}')`;
            const fontFace = new FontFace(fontFamily, source);
            FontService.LOADED_FONT_MAP[fontUid] = { fontFamily, fontWeight: undefined };
            await fontFace.load();
            document.fonts.add(fontFace);
            // console.log("Add File Font", font.title);
        }
        return { fontFamily, fontWeight: undefined };
    }

    public static async loadWebFontByFont(font: IFont) {
        if (!FontService.LOADED_FONT_MAP[font.fontUid]) {
            if (font.webFontType === WebFontType.LINK) {
                return FontService.linkFont(font);
            }
            return await FontService.fontLoad(font);
        }

        return { fontFamily: font.fontUid, fontWeight: font.weight };

    }

    public static async loadWebFont(fontUid: string) {
        try {
            if (FontService.LOADED_FONT_MAP[fontUid]) {
                return FontService.LOADED_FONT_MAP[fontUid];
            } else {
                const font = FontService.findFont(fontUid);
                // console.log("find web font", fontUid, font, FontService.FONT_ID_MAP);
                if (font) {
                    if (font.webFontType === WebFontType.LINK) {
                        return FontService.linkFont(font);
                    }
                    return await FontService.fontLoad(font);
                }
                return { fontFamily: fontUid, fontWeight: undefined };
            }
        } finally {
            // console.timeEnd("loadWebFont");
        }
    }

    public static async loadWebFontByUid(fontUid: string) {
        const font = FontService.findFont(fontUid);
        if (font) {
            if (font.webFontType === WebFontType.LINK) {
                return await FontService.linkFont(font);
            }
            return await FontService.fontLoad(font);
        }
        return { fontFamily: fontUid, fontWeight: undefined };
    }

    public static getCssFontFamilyStyle(fontUid: string) {
        const font = FontService.findFont(fontUid);
        if (font) {
            if (font.webFontType === WebFontType.LINK) {
                let _fontFamily = font.fontFamily;
                if (font.webFontLink.indexOf('https://fonts.googleapis.com') >= 0) {
                    try {
                        const familyParam = new URL(font.webFontLink).searchParams.get('family')!;
                        if (familyParam) {
                            // console.log("family param ", familyParam);
                            _fontFamily = familyParam.split(':')[0];
                            // console.log("Link Font Load From param", font, fontFamily, familyParam);
                        }
                    } catch (e) {

                    }
                }
                return { fontFamily: _fontFamily, fontWeight: font.weight || 400 };
            } else {
                const fontFamilyName = `${FontService.FILE_TYPE_PREFIX}${fontUid}`;
                return { fontFamily: fontFamilyName, fontWeight: 400 };
            }
        }
        return { fontFamily: fontUid, fontWeight: 400 };
    }

    public static getFontUidByFontInfo(fontFamily: string, fontWeight?: number) {
        // FIXME: confusing file or link type 
        const re = /^(?:vcat-|font-)(?<font>.+)/;
        const match = fontFamily.match(re);
        if (match) {
            return match.groups?.['font']!;
        }

        const font = FontService._fontList.find(e =>
            (e.fontFamily === fontFamily ||
                (fontWeight !== undefined && e.fontFamily === `${fontFamily} ${FontService.getFontWeightReplaceName(fontWeight)}`) ||
                e.orderFamily === fontFamily)
            && e.weight === (fontWeight || 400));
        if (font) {
            return font.fontUid;
        }
    }

    public static getFontWeightReplaceName(fontWeight: number) {
        if (fontWeight === 100) {
            return 'Thin';
        } else if (fontWeight === 200) {
            return 'ExtraLight';
        } else if (fontWeight === 300) {
            return 'Light';
        } else if (fontWeight === 400) {
            return 'Regular';
        } else if (fontWeight === 500) {
            return 'Medium';
        } else if (fontWeight === 600) {
            return 'SemiBold';
        } else if (fontWeight === 700) {
            return 'Bold';
        } else if (fontWeight === 800) {
            return 'ExtraBold';
        } else if (fontWeight === 900) {
            return 'Black';
        }
        return '';
    }

    public static readonly registerNewFont = async (bucket: string, path: string) => {
        const resp = await GQLClient.mutate<{ registerFontForAdmin: IFont; }, { input: { bucket: string, path: string; }; }>({
            mutation: FontService.REGISTER_FONTS,
            variables: { input: { bucket, path } },
        });

        return resp.data!.registerFontForAdmin;
    };
}

export default FontService;
