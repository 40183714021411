import { LDFeatureFlag } from "@/infra/launchdarkly/LDFeatureFlags";
import { LDFlagSet } from "launchdarkly-react-client-sdk";

export default class FlagManager {
    public static flags: LDFlagSet;

    public static readonly setFlags = (flags: LDFlagSet) => {
        this.flags = flags;
    };

    public static readonly isFlagOn = (flag: LDFeatureFlag): boolean => {
        if (!flag) {
            return true;
        }

        if (!!this.flags && this.flags[flag.flag] !== undefined) {
            return this.flags[flag.flag];
        }
        return flag.defaultValue === true; // only true is true
    };
}