import VcatColor from "@/common/styles/vcatColor.module.scss";
import Utils from "@/infra/Utils";
import { AppBar, Box, Button, Menu } from '@mui/material';
import { styled } from "@mui/material/styles";

export const HeaderWrap = styled(AppBar)(({ theme }) => ({
    left: 0,
    backgroundColor: Utils.isReal() ? "white" : "#7040F2",
    borderBottom: Utils.isReal() ? '1px solid rgba(0, 0, 0, 0.1)' : '1px solid white',
}));

export const GnbMenuList = styled(Menu)(({ theme }) => ({
    "& .MuiPaper-root": {
        minWidth: 120,
        borderRadius: 0,
        marginTop: -8,
        boxShadow: '0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        ".MuiList-root": {
            ".MuiMenuItem-root": {
                padding: '16px 24px',
                fontSize: '0.8125rem',
                fontWeight: 500,
                color: VcatColor["--g-900"],
                "&.Mui-focusVisible": {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    "&.gnb-user-menu": {
        "& .MuiPaper-root": {
            marginTop: 0,
        },
    },
}));

export const GnbMenuButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    fontWeight: 500,
    color: Utils.isReal() ? theme.palette.text.primary : "white",
    transition: "all 0.3s ease",
    "&:hover": {
        color: Utils.isReal() ? "black" : "white",
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    "&.MuiButton-sizeSmall": {
        height: 64,
        padding: "16px 24px",
    },
})) as typeof Button;

export const GnbMobileMenuList = styled(Box)(({ theme }) => ({
    width: 400,
    '@media (max-width: 480px)': {
        width: 'calc(100vw - 60px)',
    },
    height: '100%',
    overflowX: 'hidden',
    '> div': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'white',
    },
    '.MuiListItemButton-root': {
        padding: '8px 8px 8px 24px',
        '.MuiListItemText-root': {
            '.MuiTypography-root': {
                fontSize: '1rem',
                fontWeight: 700,
            },
        },
    },
    '.MuiList-root': {
        padding: 0,
        '.MuiList-root': {
            marginBottom: 16,
            '.MuiListItemButton-root': {
                padding: '8px 8px 8px 32px',
                fontSize: '0.875rem',
                fontWeight: 400,
                color: theme.palette.text.secondary,
                '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                }
            }
        }
    }
})); 