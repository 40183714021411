import IconImage from "@/common/component/card/media/IconImage";
import SnackbarListener, { SnackbarStyle } from "@/common/component/globalsnackbar/GlobalSnackbar";
import VSnackbarListener from '@/common/component/vcat/new/VSnackbar';
import Header from "@/common/layout/Header";
import HeaderMobile from '@/common/layout/HeaderMobile';
import GlobalStore from "@/GlobalStore";
import NiceModal from '@ebay/nice-modal-react';
import { Box, CssBaseline } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { shallow } from "zustand/shallow";

export interface IContentContainer {
    anonymous?: boolean;
}

const DefaultContainer = ({ anonymous }: IContentContainer) => {
    const [user] = GlobalStore.use((state) => [state.user], shallow);
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 1200) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);


    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return (<SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        iconVariant={{
            info: <IconImage name='snackbar_information' />,
            success: <IconImage name='snackbar_success' />,
            warning: <IconImage name='snackbar_warning' />,
            error: <IconImage name='snackbar_error' />
        }}
        Components={{
            default: SnackbarStyle,
            info: SnackbarStyle,
            success: SnackbarStyle,
            warning: SnackbarStyle,
            error: SnackbarStyle,
        }}
    >
        <NiceModal.Provider>
            <Box sx={{ display: "flex", minWidth: isMobile ? '100%' : 1200, backgroundColor: (theme) => theme.palette.grey[100] }}>
                <CssBaseline />
                {isMobile ?
                    <HeaderMobile />
                    :
                    <Header />
                }
                <Box component="main" sx={{
                    width: "100vw",
                    minHeight: "calc(100vh - 64px)",
                    marginTop: "64px",
                }}>
                    <Outlet />
                </Box>
                <SnackbarListener />
                <VSnackbarListener />
                {/* <useCustomModal /> */}
            </Box>
        </NiceModal.Provider>
    </SnackbarProvider>);
};

export default DefaultContainer;
