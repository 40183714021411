import EnvStore from "@/EnvStore";
import Utils from "@/infra/Utils";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const link = createHttpLink({
    uri: `${EnvStore.BASE_URL}/record/graphql`,
    credentials: 'include',
    headers: Utils.buildEnvironmentHeader()
    //credentials:'same-origin'  // api host는 static web 과 domain이 다름. same site 범위에는 포함되지만 host 가 달라 same origin 범위에 포함되지 않음.
});

//https://dev.to/tmaximini/accessing-authorization-headers-in-apollo-graphql-client-3b50
const GQLClient = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    link,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
    }
});






export default GQLClient;