import UserEmailSearch from "@/common/component/autocomplete/UserEmailSearch";
import { GlobalSnackbar } from "@/common/component/globalsnackbar/GlobalSnackbar";
import GlobalStore from "@/GlobalStore";
import useCustomModal from "@/infra/hook/useCustomModal";
import RecordApiClient from "@/infra/RecordApiClient";
import UserService, { BasicUserInfo } from "@/service/UserService";
import NiceModal from "@ebay/nice-modal-react";
import { LoadingButton } from "@mui/lab";
import { IconButton, Input, MenuItem, Select } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";


interface IUserSwitchModal extends Record<string, unknown> {

}
const UserSwitchModal = (props: IUserSwitchModal) => {
    const modal = useCustomModal();
    const [user] = GlobalStore.use(state => [state.user]);
    const [loading, setLoading] = useState<boolean>(true);
    const [targetMail, setTargetMail] = useState<string>("djhyunjin@hanmail.net");

    const [targetUser, setTargetUser] = useState<BasicUserInfo>(undefined!);
    const [link, setLink] = useState<string>(undefined!);

    const onClickAccept = async () => {
        console.log("window.location.host", window.location.host, targetUser);
        const response = await RecordApiClient.post<{ signedCode: string; }>('/switch-user', { email: targetUser.email });
        console.log(" response", response.data);
        let host = "";
        if (window.location.host.indexOf('localhost') >= 0) {
            host = "https://localhost:5000";
        } else if (window.location.host.indexOf('vcatdev.com') >= 0) {
            host = "https://vcatdev.com";
        } else if (window.location.host.indexOf('vispot.ai') >= 0) {
            host = "https://vispot.ai";
        } else if (window.location.host.indexOf('vcat.ai') >= 0) {
            host = "https://vcat.ai";
        }

        navigator.clipboard.writeText(`${host}/service/switch-user/${response.data.signedCode}`);
        setLink(`${host}/service/switch-user/${response.data.signedCode}`);
        GlobalSnackbar.success("Clipboard 에 복사된 내용을 다른 브라우저에 붙여넣기 해주세요.");

    };

    useEffect(() => {
        if (user.authorities.indexOf('ROLE_VS_CREATOR')) {
            setTargetUser({ email: "djhyunjin@hanmail.net" as string, id: undefined!, name: "", status: "live" });
        }
    }, [user]);
    const Content = useMemo(() => {
        if (!user) {
            return null;
        }

        if (user.authorities.indexOf('ROLE_VS_CREATOR') >= 0) {
            return <Select label="Select User" value={targetMail} onChange={async (event) => {
                setTargetMail(event.target.value as string);
                setLink(undefined!);
                if (event.target.value) {
                    setTargetUser({ email: event.target.value as string, id: undefined!, name: "", status: "live" });
                }
            }}>
                <MenuItem value="djhyunjin@hanmail.net">Naver</MenuItem>
                <MenuItem value="lotte_pro_v1@lotte.net">LotteOn</MenuItem>
                {/* <MenuItem value="sengwook@pioncorp.com">PION</MenuItem> */}
            </Select>;
        }

        return (<UserEmailSearch
            selectedUser={(_user: BasicUserInfo) => setTargetUser(_user)}
            clearSearchValue={() => {
                setTargetUser(undefined!);
            }}
        />);
    }, [targetUser]);

    return (
        <Dialog
            open={modal.visible}
            fullWidth
            TransitionProps={{
                onExited: () => modal.remove(),
            }}
            disableEscapeKeyDown
        >
            <IconButton onClick={() => modal.hide()}>
                <GridCloseIcon />
            </IconButton>
            <DialogTitle>User Switch</DialogTitle>
            <DialogContent>
                <div>
                    {Content}
                </div>

                {link && <div><Input fullWidth readOnly value={link} onChange={() => { }}></Input></div>}
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={false} size="medium" variant="contained" onClick={onClickAccept} disabled={!targetUser}>
                    적용
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default NiceModal.create<IUserSwitchModal>(UserSwitchModal);
