import { Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactElement, SyntheticEvent, useState } from 'react';

interface VHoverMenuProps {
    renderButton: (props: { open?: boolean, onOpen: (event: SyntheticEvent<Element, Event>) => void, onClose: () => void; }) => ReactElement<any, any>;
    renderMenu: (props: { onClose: () => void; }) => React.ReactNode;
    placement?: "top" | "right" | "bottom" | "left" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
    disableHover?: boolean;
    disablePortal?: boolean;
    offsetX?: number;
    offsetY?: number;
    leaveDelay?: number;
}

const VHoverMenu = (props: VHoverMenuProps) => {
    const { renderButton, renderMenu, placement = 'bottom-start', disableHover, disablePortal, offsetX, offsetY, leaveDelay = 100 } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            title={<>{renderMenu({ onClose: handleClose })}</>}
            placement={placement}
            arrow={false}
            disableInteractive={false}
            disableFocusListener={true}
            disableHoverListener={disableHover}
            leaveDelay={leaveDelay}
            enterTouchDelay={0}
            PopperProps={{
                disablePortal: disablePortal,
                modifiers: [{ name: "offset", options: { offset: [offsetX ? offsetX : 0, offsetY ? offsetY : 0] } }],
                sx: {
                    [`&.${tooltipClasses.popper}`]: {
                        '&[data-popper-placement*="top"]': {
                            marginBottom: .5,
                            [`& .${tooltipClasses.tooltip}`]: {
                                marginBottom: .5
                            }
                        },
                        '&[data-popper-placement*="left"]': {
                            marginRight: .5,
                            [`& .${tooltipClasses.tooltip}`]: {
                                marginRight: .5
                            }
                        },
                        '&[data-popper-placement*="right"]': {
                            marginLeft: .5,
                            [`& .${tooltipClasses.tooltip}`]: {
                                marginLeft: .5
                            }
                        },
                        '&[data-popper-placement*="bottom"]': {
                            marginTop: .5,
                            [`& .${tooltipClasses.tooltip}`]: {
                                marginTop: .5
                            }
                        }
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                        maxWidth: 'none',
                        backgroundColor: 'transparent',
                        border: 0,
                        borderRadius: 0,
                        margin: 0,
                        padding: 0,
                    }
                }
            }}
        >
            <Box display='flex'>
                {renderButton({ open: open, onOpen: handleOpen, onClose: handleClose })}
            </Box>
        </Tooltip>
    );
};

export default VHoverMenu;