class GqlHelper {
    public static readonly pageableGql = (contentBody: string) => {
        return `content {
            ${contentBody}
          }
          totalElements
            totalPages
            number
            size
            first
            last
            numberOfElements`;
    };
}
export default GqlHelper;