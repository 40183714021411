import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useLocationChange = (onChange: Function) => {
    const location = useLocation();
    const prevLocation = useRef<string | null>(null);

    useEffect(() => {
        if (!prevLocation.current) {
            prevLocation.current = location.pathname;
        } else {
            if (prevLocation.current !== location.pathname) {
                onChange();
            }
        }
    }, [location]);

    return location;
};

export default useLocationChange;