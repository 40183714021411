import IconImage from '@/common/component/card/media/IconImage';
import { headersData, IHeader } from '@/common/layout/Header';
import { GnbMobileMenuList, HeaderWrap } from '@/common/layout/Header.style';
import GlobalStore from "@/GlobalStore";
import FlagManager from '@/infra/launchdarkly/FlagManager';
import Utils from '@/infra/Utils';
import UserSwitchModal from '@/pages/modal/UserSwitchModal';
import NiceModal from "@ebay/nice-modal-react";
import { SwitchAccessShortcutAddOutlined } from '@mui/icons-material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Collapse, Drawer, List, ListItemButton, ListItemText, Menu, Stack } from '@mui/material';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";

type Anchor = 'left';
interface menuState {
    [key: string]: boolean;
}

export default function HeaderMobile() {
    const location = useLocation();
    const [openMap, setOpenMap] = React.useState<menuState>({});
    const [gnbMenu, setGnbMenu] = useState<Array<IHeader>>([]);
    const [selectedItem, setSelectedItem] = useState('');

    useEffect(() => {
        const filtered = headersData;
        setGnbMenu(filtered);
    }, []);

    const handleCollapseOpen = (key: string) => {
        setOpenMap({ [key]: !openMap[key] });
        if (selectedItem === key) {
            setSelectedItem('');
        } else {
            setSelectedItem(key);
        }
    };

    const [state, setState] = React.useState({ left: false });

    const toggleDrawer = (anchor: Anchor, open: boolean) => () => {
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: Anchor) => (
        <GnbMobileMenuList
            role="presentation"
            sx={{ overflowY: 'scroll' }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center' pl={2} py={1.5}>
                <IconButton
                    size='small'
                    onClick={toggleDrawer('left', false)}
                >
                    <IconImage width={30} name='close' color='grey' />
                </IconButton>
            </Stack>
            <List>
                {gnbMenu.map((item, index: number) => {
                    const open = openMap[item.label] || false;
                    return (
                        <React.Fragment key={index}>
                            {item.children && item.children.length &&
                                <React.Fragment>

                                    <ListItemButton
                                        onClick={() => handleCollapseOpen(item.label)}
                                        selected={selectedItem === item.label || open}
                                    >
                                        <ListItemText primary={item.label} />
                                        {open ? <IconImage name='chevron-up' /> : <IconImage name='chevron-down' />}
                                    </ListItemButton>
                                    <Collapse in={open} timeout="auto" unmountOnExit>

                                        <List component="div">
                                            {item.children &&
                                                item.children.map((sub: any, index: number) => {
                                                    if (!!sub.featureFlag && !FlagManager.isFlagOn(sub.featureFlag)) {
                                                        return null;
                                                    }

                                                    return (
                                                        <ListItemButton
                                                            key={index}
                                                            component={RouterLink}
                                                            to={sub.to}
                                                            onClick={toggleDrawer('left', false)}
                                                            selected={location.pathname === sub.to || selectedItem === sub.label}
                                                        >
                                                            {sub.label}
                                                        </ListItemButton>
                                                    );
                                                })}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            }
                            {item.to &&
                                <ListItemButton
                                    component={RouterLink}
                                    to={item.to}
                                    onClick={toggleDrawer('left', false)}
                                >
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            }
                        </React.Fragment>
                    );
                })}
            </List>
        </GnbMobileMenuList>
    );

    const AvatarMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const [signOut, hasRole] = GlobalStore.use(state => [state.signOut, state.hasRole], shallow);
        const onSignOut = async () => {
            try {
                signOut();
                handleClose();
            } finally {
            }
        };
        const onSwitchUser = () => {
            NiceModal.show(UserSwitchModal, {});
            handleClose();
        };

        return (
            <>
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <img src="/assets/images/avatar.svg" alt="avatar" />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {hasRole(['ROLE_VS_CS', 'ROLE_VS_CREATOR']) &&
                        <MenuItem onClick={onSwitchUser}>
                            <SwitchAccessShortcutAddOutlined fontSize="small" sx={{ mr: 1 }} />
                            SwitchUser
                        </MenuItem>
                    }
                    <MenuItem onClick={onSignOut}>
                        <LogoutOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                        Logout
                    </MenuItem>
                </Menu>
            </>
        );
    };

    return (
        <Box>
            <HeaderWrap>
                <Toolbar sx={{ height: 64 }}>
                    <IconButton
                        edge="start"
                        size='small'
                        sx={{ mr: 1 }}
                        id="mobile-button"
                        onClick={toggleDrawer('left', true)}
                    >
                        <IconImage name='hamburger' color={Utils.isReal() ? '' : 'white'} />
                    </IconButton>
                    <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {list('left')}
                    </Drawer>

                    <Link component={RouterLink} to="/" sx={{ display: 'flex' }}>
                        <Box component="img" mr={4} src={Utils.isReal() ? '/assets/images/record_logo.svg' : '/assets/images/record_logo_w.svg'} alt="Record Logo" sx={{ height: 24 }} />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />

                    {['/motion/u2v/dpcut'].indexOf(location.pathname) < 0 && <AvatarMenu />}

                </Toolbar>
            </HeaderWrap>
        </Box>
    );
}
