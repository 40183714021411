import { styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';

const IconImageWrap = styled('img')`
    &.p-5, &.primary {
        filter: brightness(0) saturate(100%) invert(22%) sepia(52%) saturate(3931%) hue-rotate(208deg) brightness(105%) contrast(107%);
    }
    &.g-5, &.gray {
        filter: brightness(0) saturate(100%) invert(57%) sepia(13%) saturate(236%) hue-rotate(169deg) brightness(96%) contrast(86%);
    }
    &.g-3, &.disabled {
        filter: brightness(0) saturate(100%) invert(85%) sepia(7%) saturate(194%) hue-rotate(169deg) brightness(98%) contrast(95%);
    }
    &.white {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(348deg) brightness(106%) contrast(101%);
    }
    &.secondary {
        filter: brightness(0) saturate(100%) invert(50%) sepia(76%) saturate(1241%) hue-rotate(112deg) brightness(100%) contrast(91%);
    }
    &.success {
        filter: brightness(0) saturate(100%) invert(44%) sepia(99%) saturate(1336%) hue-rotate(111deg) brightness(104%) contrast(95%);
    }
    &.warning {
        filter: brightness(0) saturate(100%) invert(66%) sepia(90%) saturate(609%) hue-rotate(357deg) brightness(95%) contrast(108%);
    }
    &.error {
        filter: brightness(0) saturate(100%) invert(24%) sepia(80%) saturate(1734%) hue-rotate(329deg) brightness(119%) contrast(106%);
    }
`;

interface IIconImageProps {
    width?: number | string;
    height?: number | string;
    name: string;
    color?: string;
    sx?: SxProps<Theme> | undefined;
    draggable?: boolean;
}

const IconImageMap: any = {};
const IconImage = (props: IIconImageProps) => {
    const {
        width,
        height,
        name,
        color,
        sx,
        draggable = false
    } = props;

    let src = `/assets/images/icons/${name}.svg`;

    if (name.startsWith("data:")) {
        src = name;
    }
    return (
        <IconImageWrap
            src={src}
            className={color}
            // cors 이슈가 있는경우가 있어서 crossOrigin 을 사용한건가요?
            // crossOrigin="use-credentials"
            alt={name}
            draggable={draggable}
            sx={{
                width: width,
                height: height,
                ...sx,
            }}
        />
    );
};

export default IconImage;