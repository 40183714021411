import VText from '@/common/component/vcat/VText';
import GlobalStore from '@/GlobalStore';
import { LoadingButton } from '@mui/lab';
import { Box, CssBaseline, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextField } from 'mui-rff';
import { useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { shallow } from "zustand/shallow";

const Wrap = styled(Box)`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f0f0f3;
	article {
		width: 360px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: auto;
		form {
			border-radius: 24px;
			background: #f0f0f3;
			box-shadow: -15px -15px 30px #fff, 15px 15px 30px #aeaec040;
			margin-top: 24px;
			padding: 36px;
			div {
				margin-bottom: 8px;
			}
		}
	}
	footer {
		margin-top: auto;
		margin-bottom: 24px;
		color: #c2c2c2;
	}
`;

interface SignInData {
    email: string;
    password: string;
}

const { Form } = withTypes<SignInData>();

export default function SignIn() {
    const [user, signIn] = GlobalStore.use((state) => ([state.user, state.signIn]), shallow);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [processing, setProcessing] = useState<boolean>(false);

    useEffect(() => {
        if (user) navigate(from, { replace: true });
    }, [user]);

    const onSignIn = async (value: SignInData) => {
        setProcessing(true);
        try {
            await signIn(value.email, value.password);
        } catch (e) {
        } finally {
            setProcessing(false);
        }
    };

    return (<Wrap>
        <CssBaseline />
        <article>
            <Link component={RouterLink} to="/">
                <Box
                    component="img"
                    src={'/assets/images/record_logo.svg'}
                    alt="Record Logo"
                    sx={{ height: 48 }}
                />
            </Link>
            <Form
                onSubmit={onSignIn}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required={true}
                            size="small"
                            autoFocus
                        />
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete={'current-password'}
                            required={true}
                            size="small"
                        />
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            size="medium"
                            fullWidth
                            loading={processing}
                        >
                            Sign in
                        </LoadingButton>
                    </form>
                )}
            />
        </article>
        <footer>
            <VText color='textTertiary' label='© Pion Corporation All rights reserved.' />
        </footer>
    </Wrap>);
}
